<template>
    <div>
        <div class="header">
            <van-icon name="arrow-left" @click="$router.push({name: '我'})"/>
            <div>服务</div>
        </div>
        <div class="top">
            <div class="item">
                <img style="width:40px" src="/images/receipt-payment.png" alt="">
                <div class="text" style="font-weight:500">收付款</div>
            </div>
            <div class="item" @click="toBill">
                <img src="/images/wallet.png" alt="">
                <div class="text" style="font-weight:500">钱包</div>
                <div class="value">¥{{ returnFloat(cash) }}</div>
            </div>

        </div>
        <div class="card" v-for="item in list" :key="item.name">
            <div class="name">{{ item.name }}</div>
            <div class="list">
                <div v-for="ele in item.list" :key="ele.id" class="item">
                    <img style="width:25px;padding-bottom:5px" :src="ele.icon" alt="">
                    <div class="text" style="font-weight:500">{{ ele.text }}</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            list: [
                { name: '金融理财', list: [
                    { id: 0, icon: '/images/money-mac.png', text: '理财通' },
                    { id: 1, icon: '/images/insurance-service.png', text: '保险服务' }
                ]},
                { name: '生活服务', list: [
                    { id: 0, icon: '/images/mobile-recharge.png', text: '手机充值' },
                    { id: 1, icon: '/images/living-pay.png', text: '生活缴费' },
                    { id: 2, icon: '/images/q-charge.png', text: 'Q币充值' },
                    { id: 3, icon: '/images/city-service.png', text: '城市服务' },
                    { id: 4, icon: '/images/tencent-charity.png', text: '腾讯公益' },
                    { id: 5, icon: '/images/medical-treatment.png', text: '医疗健康' },
                ]},
                { name: '交通出行', list: [
                    { id: 0, icon: '/images/mobility-service.png', text: '出行服务' },
                    { id: 1, icon: '/images/train-ticket.png', text: '火车票机票' },
                    { id: 2, icon: '/images/didi.png', text: '滴滴出行' },
                    { id: 3, icon: '/images/hotel.png', text: '酒店' },
                ]},
            ],
        }
    },
    computed: {
        ...mapState({
            cash: state => state.fund.coin
        })
    },
    methods: {
        toBill() {
            this.$router.push({ path: '/self/service/bill' })
        },
        returnFloat(num) {
    num = num.toString().replace(/,/g, ""); // 转成字符串类型  如能确定类型 这步可省去
      console.log(num)
      if (num.indexOf(".") !== -1) {
        let [integerPart, decimalPart] = num.split(".");
     
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.substring(0, 2);
        } else if (decimalPart.length === 1) {
          decimalPart += "0";
        }
     
        num = `${integerPart}.${decimalPart}`;
      } else {
        num += ".00";
      }
     
      return num;
    }
    }

}



</script>
<style lang="less" scoped>
.header {
    position: relative;
    z-index: 99;
    // overflow: hidden;
    height: 45px;
    display: flex;
    align-items: center;
    // font-size: 15px;
    padding: 0 15px 0 10px;
    line-height: 45px;
    background: #fff;
    opacity: 1;
    color: #000;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 17px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;
    div {
        flex: 1;
        margin-left: -17px;
    }
}
.top {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 10px;
    background: #2BAC69;
    border-radius: 6px;
    padding-top: 30px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 16px;
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
            margin-top: 10px;
        }
        .value {
            color: rgba(255,255,255,0.6);
        }
    }
}
.card {
    background: #fff;
    border-radius: 6px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
    padding-left: 14px;
    padding-top: 14px;
    padding-right: 14px;
    font-size: 14px;
    color: rgba(0,0,0,0.6);
    .name {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .item {
            width: 25%;
            text-align: center;
            color: #000;
            margin-bottom: 30px;
        }
    }
}

</style>